* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;


  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

/* Styles for the scrollbar track */
::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
  background-color: rgba(0, 0, 0, 0, 0.048);
}

/* Styles for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #ff7e5f, #62a4ff); /* Gradient background color */
  border-radius: 10px; /* Rounded corners */
}

/* Change thumb color when hovering */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #ff6f4e, #62a4ff);
}

/* Change thumb color when clicking */
::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to bottom, #ff6340, #fb8e5d);
}

::-webkit-scrollbar:horizontal {
  height: 3px; /* Height of the scrollbar */
}

::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(to right, #ff7e5f, #62a4ff); /* Gradient background color */
}