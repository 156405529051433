nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 2;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #ffffff;
}

button.navToggle {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Closed state: Set width to 0 */
nav.closed {
  width: 0;
}

.background.closed {
  width: 0;
}

.menu-item-link {
  text-decoration: none;
  color: inherit;
  position: relative;
  display: inline-block;
}

.menu-item-link::after {
  content: "";
  display: block;
  width: 100%; /* Match the width of the text */
  height: 2px; /* Height of the underscore */
  background-color: var(--underline-color); /* Use the passed color */
  transform: scaleX(0); /* Initially hidden (scaled to 0) */
  transform-origin: left; /* Scale from left to right */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effect */
}

.menu-item-link:hover::after {
  transform: scaleX(1); /* Fully reveal the underscore on hover */
}