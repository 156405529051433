.postDetailPage {
    background-color: #DEE2E6;
    padding: 2rem; /* Reduce padding for smaller screens */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    flex-wrap: wrap; /* Allow items to wrap */
  }
  
  .info-container {
    background-color: #E9ECEF;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    flex: 0 1 100%; /* Take full width on smaller screens */
    margin: 3rem auto; /* Add some space between sections */

    h1 {
        margin-bottom: 1rem;
    }
  }
  
  .renderer-container {
    background-color: #F8F9FA;
    padding: 5rem;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    flex: 1 1 100%; /* Take full width on smaller screens */
  }

  .badge {
    margin-right: 0.5rem;
    color: white;
}

.badge-cat{
    background-color: #007BFF;
}
  
  @media (min-width: 768px) {
    .info-container {
      flex: 0 1 300px;
      margin-right: 2rem;
      order: -1; /* Move the info-container to the left */
    }
  
    .renderer-container {
      flex: 1 1 calc(60% - 2rem); /* Adjust width to accommodate margin */
    }
  }

  .return-top {
    position: fixed;
    bottom: 90px;
    right: 30px;
    color: #007BFF;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .return-top:hover {
    color: #ef900c;
  }

  .return-bottom {
    position: fixed;
    bottom: 30px;
    right: 30px;
    color: #007BFF;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .return-bottom:hover {
    color: #ef900c;
  }

.notFoundImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: linear-gradient(to right, #ff6f4e, #62a4ff);
  transform-origin: 0%;
}
  